<template>
	<v-sheet class="recurring-main-listing position-relative">
		<v-row class="m-0">
			<v-col md="12">
				<v-row>
					<v-col md="4">
						<v-card outlined style="max-height: 455px; height: 440px">
							<v-card-text>
								<div class="font-level-3-bold">
									<span style="color: black; font-weight: 700">Task Completion Rate</span>
								</div>
								<v-spacer></v-spacer>
							</v-card-text>
							<div class="mx-auto" style="max-width: 400px">
								<div>
									<apexchart type="pie" :options="chartOptions" :series="series"></apexchart>
								</div>
								<ul class="ul statics d-flex">
									<li class="li text-center">
										<div class="color747474">ON TIME</div>
										<div class="mt-1">
											<v-icon size="28px" color="green">mdi-arrow-down</v-icon>
											<span class="number green--text">62.6% (25 K)</span>
										</div>
									</li>
									<li class="li text-center">
										<div class="color747474">PAST DUE</div>
										<div class="mt-1">
											<v-icon size="28px" color="blue">mdi-arrow-up </v-icon>
											<span class="number blue--text">36.2% (25 K)</span>
										</div>
									</li>
								</ul>
							</div>
						</v-card>
					</v-col>
					<v-col md="8">
						<v-card outlined>
							<v-card-text
								><div class="font-level-3-bold">
									<span style="color: black; font-weight: 700">Project Expiring In 3 Months</span>
								</div>
								<div class="team-preview">
									<div class="v-data-table bt-table theme--light">
										<div class="v-data-table__wrapper" style="max-height: 385px; height: 385px">
											<table class="mt-4" style="width: 100%">
												<thead>
													<tr>
														<th
															class="fw-600 px-3 py-2 sortable"
															style="border-right: 0.5px solid rgb(177, 176, 176)"
															@click="sortTable('proj')"
														>
															Projects
															<v-icon small>
																{{
																	sortBy === "proj"
																		? sortDesc
																			? "mdi-arrow-down"
																			: "mdi-arrow-up"
																		: "mdi-arrow-down"
																}}
															</v-icon>
														</th>
														<th
															class="fw-500 px-3 py-1 sortable"
															style="border-right: 0.5px solid rgb(177, 176, 176)"
															@click="sortTable('month_left')"
														>
															Month Left
															<v-icon small>
																{{
																	sortBy === "month_left"
																		? sortDesc
																			? "mdi-arrow-down"
																			: "mdi-arrow-up"
																		: "mdi-arrow-down"
																}}
															</v-icon>
														</th>
														<th class="fw-500 px-3 py-1 sortable" @click="sortTable('kpi_status')">
															KPI Status
															<v-icon small>
																{{
																	sortBy === "kpi_status"
																		? sortDesc
																			? "mdi-arrow-down"
																			: "mdi-arrow-up"
																		: "mdi-arrow-down"
																}}
															</v-icon>
														</th>
													</tr>
												</thead>
												<tbody>
													<tr
														v-for="(member, index) in projectExpiringMonth"
														:key="index"
														class="promised-table-tr"
													>
														<td class="fw-600 px-3 py-2 table_border">
															{{ member.proj }}
														</td>
														<td class="fw-500 px-3 py-1 table_border">
															<v-avatar color="#0D47A1" size="30">
																<span class="white--text">{{ member.month_left }}</span>
															</v-avatar>
														</td>
														<td class="fw-500 px-3 py-1">
															<v-chip :color="getStatusColor(member.kpi_status)">
																<span class="white--text">{{ member.kpi_status }}</span>
															</v-chip>
														</td>
													</tr>
												</tbody>
											</table>
										</div>
									</div>
								</div>
							</v-card-text>
						</v-card>
					</v-col>
				</v-row>
			</v-col>
		</v-row>
		<v-row class="m-0">
			<v-col md="12">
				<v-row>
					<v-col md="8">
						<v-card outlined>
							<v-card-text>
								<v-flex class="d-flex justify-content-between">
									<div class="font-level-3-bold d-flex align-center py-0">
										<span style="color: black; font-weight: 600">My Tasks</span>
										<div class="d-flex">
											<v-select
												class="cm-menu-select elevation-0 red--text"
												v-model="status"
												:items="domain_status_list"
												hide-details
												dense
												solo
											>
												<template v-slot:item="{ item }">
													<v-list-item-content>
														<v-list-item-title v-text="item.text"></v-list-item-title>
														<v-list-item-subtitle v-text="item.description"></v-list-item-subtitle>
													</v-list-item-content>
													<v-list-item-action>
														<v-avatar size="26" :color="item.status_color" :text-color="item.textcolor">
															<span>{{ item.status_count }}</span>
														</v-avatar>
													</v-list-item-action>
												</template>
											</v-select>
										</div>
									</div>
									<div class="font-level-3-bold">
										<v-text-field outlined hide-details placeholder="My Task"></v-text-field>
									</div>
								</v-flex>
								<div class="team-preview">
									<div class="v-data-table bt-table theme--light">
										<div class="v-data-table__wrapper" style="max-height: 300px; height: 300px">
											<table class="mt-4" style="width: 100%">
												<thead>
													<tr>
														<th
															class="fw-600 px-3 py-2 sortable"
															style="border-right: 0.5px solid rgb(177, 176, 176)"
															@click="sortTable('proj')"
														>
															Task Name
															<v-icon small>
																{{
																	sortBy === "proj"
																		? sortDesc
																			? "mdi-arrow-down"
																			: "mdi-arrow-up"
																		: "mdi-arrow-down"
																}}
															</v-icon>
														</th>
														<th
															class="fw-500 px-3 py-1 sortable"
															style="border-right: 0.5px solid rgb(177, 176, 176)"
															@click="sortTable('status')"
														>
															Status
															<v-icon small>
																{{
																	sortBy === "status"
																		? sortDesc
																			? "mdi-arrow-down"
																			: "mdi-arrow-up"
																		: "mdi-arrow-down"
																}}
															</v-icon>
														</th>
														<th class="fw-500 px-3 py-1 sortable table_border" @click="sortTable('date_start')">
															Start Date
															<v-icon small>
																{{
																	sortBy === "date_start"
																		? sortDesc
																			? "mdi-arrow-down"
																			: "mdi-arrow-up"
																		: "mdi-arrow-down"
																}}
															</v-icon>
														</th>
														<th class="fw-500 px-3 py-1 sortable table_border" @click="sortTable('priority')">
															Completed / Due Date
															<v-icon small>
																{{
																	sortBy === "priority"
																		? sortDesc
																			? "mdi-arrow-down"
																			: "mdi-arrow-up"
																		: "mdi-arrow-down"
																}}
															</v-icon>
														</th>
														<th class="fw-500 px-3 py-1 sortable table_border" @click="sortTable('priority')">
															Priority
															<v-icon small>
																{{
																	sortBy === "priority"
																		? sortDesc
																			? "mdi-arrow-down"
																			: "mdi-arrow-up"
																		: "mdi-arrow-down"
																}}
															</v-icon>
														</th>
													</tr>
												</thead>
												<tbody>
													<tr
														v-for="(myTask, index) in sortTaskMemberList"
														:key="index"
														class="promised-table-tr"
													>
														<td class="fw-600 px-3 py-2 table_border">
															<span>{{ myTask?.proj }}</span
															><br />
															<span v-if="myTask?.project_name" class="ml-4"> - {{ myTask?.project_name }}</span>
															<em v-else class="text--secondary ml-4">- No Project</em>
														</td>
														<td class="fw-500 px-3 py-1 table_border">
															<v-chip class="ma-2" :color="getStatusColor(myTask.status)" label>
																<span class="white--text">{{ myTask?.status }}</span>
															</v-chip>
														</td>
														<td class="fw-500 px-3 py-1 table_border">
															<span v-if="myTask.date_start">{{ myTask?.date_start }}</span>
															<em v-else class="text--secondary">No Start Date</em>
														</td>
														<td class="fw-500 px-3 py-1 table_border">
															<span v-if="myTask.completed_date">{{ myTask?.completed_date }}</span>
															<em v-else class="text--secondary">No Completed Date</em>
														</td>
														<td class="fw-500 px-3 py-1">
															<span v-if="myTask.priority">
																<v-chip class="ma-2 white--text" :color="getStatusColor(myTask?.priority)">
																	{{ myTask?.priority }}
																</v-chip>
															</span>
															<em v-else class="text--seconday">No priority</em>
														</td>
													</tr>
												</tbody>
											</table>
										</div>
									</div>
								</div>
							</v-card-text>
						</v-card>
					</v-col>

					<v-col md="4">
						<v-card outlined style="max-height: 365px; height: 365px">
							<v-layout class="px-4 py-2">
								<div class="font-level-3-bold">
									<span style="color: black; font-weight: 600">My Task Count</span>
								</div>
								<v-spacer></v-spacer>
							</v-layout>
							<div class="mx-auto px-4" style="max-width: 600px">
								<div>
									<apexchart type="bar" :options="taskChartOptions" :series="taskSeries"></apexchart>
								</div>
							</div>
						</v-card>
					</v-col>
				</v-row>
			</v-col>
		</v-row>
	</v-sheet>
</template>
<script>
import MomentJS from "moment-timezone";
export default {
	name: "SeoExecutive",
	data() {
		return {
			series: [25, 44],
			chartOptions: {
				chart: {
					width: "100%",
					type: "pie",
				},
				labels: ["ON TIME", "PAST DUE"],
				theme: {
					monochrome: {
						enabled: false,
					},
				},
				plotOptions: {
					pie: {
						dataLabels: {
							offset: -5,
						},
					},
				},
				legend: {
					show: false,
				},
			},
			taskSeries: [
				{
					name: "TOTAL COUNT",
					data: [44, 55, 34],
				},
				{
					name: "DUE THIS WEEK",
					data: [13, 23, 26],
				},
			],
			taskChartOptions: {
				chart: {
					type: "bar",
					height: 350,
					stacked: true,
					toolbar: {
						show: true,
					},
					zoom: {
						enabled: true,
					},
				},
				responsive: [
					{
						breakpoint: 480,
						options: {
							legend: {
								position: "bottom",
								offsetX: -10,
								offsetY: 0,
							},
						},
					},
				],
				plotOptions: {
					bar: {
						horizontal: false,
						borderRadius: 10,
						dataLabels: {
							total: {
								enabled: true,
								style: {
									fontSize: "13px",
									fontWeight: 900,
								},
							},
						},
					},
				},
				xaxis: {
					categories: ["NOT STARTED", "IN PROGRESS", "COMLETED"],
				},
				legend: {
					position: "right",
					offsetY: 40,
				},
				fill: {
					opacity: 1,
				},
			},
			dataTab: "seo_executive",
			page: 1,
			domainDialog: false,
			checkbox: false,
			project: "",
			status: "all",
			entity: "",
			date_range: [],
			domain_status_list: [
				{
					id: 192,
					text: "Team Member",
					value: "all",
					type: 40,
					status_color: "blue",
					count: 0,
					status_count: 0,
				},
				{
					id: 194,
					text: "Active",
					value: "active",
					type: 40,
					status_color: "green",
					count: 0,
					status_count: 0,
				},
				{
					id: 193,
					text: "Expired",
					value: "expired",
					type: 40,
					status_color: "red",
					count: 0,
					status_count: 0,
				},
				{
					id: 194,
					text: "Expire in 1 Month",
					value: "expired_in_1_month",
					type: 40,
					status_color: "orange",
					count: 0,
					status_count: 0,
				},
			],
			domainStatusList: [
				{
					text: "Expired",
					value: "expired",
				},
				{
					text: "Renew",
					value: "renew",
				},
			],
			members_list: {
				total_summary: [
					{
						id: 1,
						c_heading: "TOTAL PROJECTS",
						total_projects: "23",
					},
					{
						id: 2,
						c_heading: "GREEN",
						total_green: "7",
					},
					{
						id: 2,
						c_heading: "TOTAL PROJECTS",
						total_red: "10",
					},
				],
				projectExpiring: [
					{
						id: 1,
						proj: "H1",
						month_left: 2.5,
						kpi_status: "GREEN",
					},
					{
						id: 2,
						proj: "H2",
						month_left: 3,
						kpi_status: "RED",
					},
					{
						id: 3,
						proj: "H3",
						month_left: 5,
						kpi_status: "BLUE",
					},
					{
						id: 4,
						proj: "H4",
						month_left: 2.5,
						kpi_status: "GREEN",
					},
					{
						id: 5,
						proj: "H5",
						month_left: 3,
						kpi_status: "RED",
					},
					{
						id: 6,
						proj: "H6",
						month_left: 5,
						kpi_status: "BLUE",
					},
					{
						id: 7,
						proj: "H7",
						month_left: 3,
						kpi_status: "RED",
					},
					{
						id: 1,
						proj: "H1",
						month_left: 2.5,
						kpi_status: "GREEN",
					},
					{
						id: 2,
						proj: "H2",
						month_left: 3,
						kpi_status: "RED",
					},
					{
						id: 3,
						proj: "H3",
						month_left: 5,
						kpi_status: "BLUE",
					},
					{
						id: 4,
						proj: "H4",
						month_left: 2.5,
						kpi_status: "GREEN",
					},
					{
						id: 5,
						proj: "H5",
						month_left: 3,
						kpi_status: "RED",
					},
					{
						id: 6,
						proj: "H6",
						month_left: 5,
						kpi_status: "BLUE",
					},
					{
						id: 7,
						proj: "H7",
						month_left: 3,
						kpi_status: "RED",
					},
				],
				allTaskMemberListData: [
					{
						id: 1,
						proj: "TASK H1",
						project_name: "Business",
						status: "Completed",
						date_start: "20-sep-2023",
						completed_date: "26-sep-2023",
						priority: "High",
					},
					{
						id: 2,
						proj: "TASK H2",
						project_name: "Genic",
						status: "In Progress",
						date_start: "",
						completed_date: "26-sep-2023",
						priority: "Medium",
					},
					{
						id: 3,
						proj: "TASK H3",
						project_name: "",
						status: "Not Status",
						date_start: "23-sep-2023",
						completed_date: "26-sep-2023",
						priority: "Low",
					},
					{
						id: 4,
						proj: "TASK H4",
						project_name: "",
						status: "Completed",
						date_start: "20-sep-2023",
						completed_date: "",
						priority: "High",
					},
					{
						id: 5,
						proj: "TASK H5",
						project_name: "Thrust",
						status: "In Progress",
						date_start: "",
						completed_date: "26-sep-2023",
						priority: "Medium",
					},
					{
						id: 6,
						proj: "TASK H6",
						project_name: "Teasure Trove",
						status: "Not Status",
						date_start: "23-sep-2023",
						completed_date: "",
						priority: "Low",
					},
					{
						id: 7,
						proj: "TASK H7",
						project_name: "Business",
						status: "Completed",
						date_start: "20-sep-2023",
						completed_date: "26-sep-2023",
						priority: "High",
					},
					{
						id: 8,
						proj: "TASK H8",
						project_name: "Genic",
						status: "In Progress",
						date_start: "",
						completed_date: "26-sep-2023",
						priority: "Medium",
					},
					{
						id: 9,
						proj: "TASK H10",
						project_name: "",
						status: "Not Status",
						date_start: "23-sep-2023",
						completed_date: "26-sep-2023",
						priority: "Low",
					},
					{
						id: 11,
						proj: "TASK H11",
						project_name: "",
						status: "Completed",
						date_start: "20-sep-2023",
						completed_date: "",
						priority: "High",
					},
					{
						id: 12,
						proj: "TASK H12",
						project_name: "Thrust",
						status: "In Progress",
						date_start: "",
						completed_date: "26-sep-2023",
						priority: "Medium",
					},
					{
						id: 13,
						proj: "TASK H13",
						project_name: "Teasure Trove",
						status: "Not Status",
						date_start: "23-sep-2023",
						completed_date: "",
						priority: "Low",
					},
					{
						id: 14,
						proj: "TASK H14",
						project_name: "Business",
						status: "Completed",
						date_start: "20-sep-2023",
						completed_date: "26-sep-2023",
						priority: "High",
					},
					{
						id: 15,
						proj: "TASK H15",
						project_name: "Genic",
						status: "In Progress",
						date_start: "",
						completed_date: "26-sep-2023",
						priority: "Medium",
					},
					{
						id: 16,
						proj: "TASK H16",
						project_name: "",
						status: "Not Status",
						date_start: "23-sep-2023",
						completed_date: "26-sep-2023",
						priority: "Low",
					},
					{
						id: 17,
						proj: "TASK H17",
						project_name: "",
						status: "Completed",
						date_start: "20-sep-2023",
						completed_date: "",
						priority: "High",
					},
					{
						id: 18,
						proj: "TASK H18",
						project_name: "Thrust",
						status: "In Progress",
						date_start: "",
						completed_date: "26-sep-2023",
						priority: "Medium",
					},
				],
			},
			sortBy: "",
			sortDesc: false,
			size: 70,
			width: 8,
			rotate: 360,
			color: "green",
			currentPercentage: 1,
			targetPercentage: 75,
			duration: 1000,
			increment: 1,
			currentPercentageRed: 1,
			targetPercentageRed: 65,
		};
	},
	mounted() {
		this.startAnimation();
		this.startAnimationRed();
	},
	methods: {
		startAnimation() {
			const intervalId = setInterval(() => {
				if (this.currentPercentage < this.targetPercentage) {
					this.currentPercentage += this.increment;
				} else {
					clearInterval(intervalId);
				}
			}, this.duration / ((this.targetPercentage - this.currentPercentage) / this.increment));
		},
		startAnimationRed() {
			const intervalIdRed = setInterval(() => {
				if (this.currentPercentageRed < this.targetPercentageRed) {
					this.currentPercentageRed += this.increment;
				} else {
					clearInterval(intervalIdRed);
				}
			}, this.duration / ((this.targetPercentageRed - this.currentPercentageRed) / this.increment));
		},
		sortTable(column) {
			if (this.sortBy === column) {
				this.sortDesc = !this.sortDesc;
			} else {
				this.sortBy = column;
				this.sortDesc = false;
			}
		},
		getStatusColor(status) {
			switch (status) {
				case "Completed":
					return "#81C784";
				case "In Progress":
					return "#E57373";
				case "High":
					return "green";
				case "Medium":
					return "blue";
				case "Low":
					return "red";
				case "GREEN":
					return "green";
				case "RED":
					return "red";
				case "BLUE":
					return "blue";
				default:
					return "#64B5F6";
			}
		},
		// getStatusColor(status) {
		// 	if (status == "Expired") {
		// 		return "red";
		// 	} else if (status == "Expire In 1 Month") {
		// 		return "orange";
		// 	} else if (status == "Active") {
		// 		return "green";
		// 	}
		// },
		date_difference(start, end) {
			let start_Date = MomentJS(start);
			let end_Date = MomentJS(end);
			let totalDays = end_Date.diff(start_Date, "days");
			return totalDays;
		},
	},
	computed: {
		projectExpiringMonth() {
			const sortedList = [...this.members_list.projectExpiring];
			if (this.sortBy) {
				sortedList.sort((a, b) => {
					const aValue = a[this.sortBy];
					const bValue = b[this.sortBy];
					if (aValue < bValue) return this.sortDesc ? 1 : -1;
					if (aValue > bValue) return this.sortDesc ? -1 : 1;
					return 0;
				});
			}

			return sortedList;
		},
		sortTaskMemberList() {
			const sortedList = [...this.members_list.allTaskMemberListData];
			if (this.sortBy) {
				sortedList.sort((a, b) => {
					const aValue = a[this.sortBy];
					const bValue = b[this.sortBy];
					if (aValue < bValue) return this.sortDesc ? 1 : -1;
					if (aValue > bValue) return this.sortDesc ? -1 : 1;
					return 0;
				});
			}

			return sortedList;
		},
		taskStatusBorder: function () {
			return function name(status) {
				if (status == "Expired") {
					return "border:1px solid red";
				}
				if (status == "Active") {
					return "border:1px solid green";
				}
			};
		},
	},
	components: {
		// SelectInput,
		// Chip,
		// ShowTextAreaValue,
		// ShowValue,
		// UpdateDomain,
	},
};
</script>

<style>
.sortable {
	cursor: pointer;
}
.table_border {
	border-right: 0.5px solid rgb(177, 176, 176);
}
.promised-table-tr:nth-child(odd) {
	background-color: rgb(243, 243, 243);
}
</style>
